import { useEffect, useMemo, useState } from 'react';
import cls from './tabs.module.css';
import { HStack } from '../../../Stack';
import { FakeItemTab } from '../FakeItemTab/FakeItemTab';
import { ItemTab } from '../ItemTab/ItemTab';
import { classNames } from '@/shared/lib/classNames/classNames';
import { TypeTabs } from '@/shared/types/tabs';

interface ITabsProps<T> {
  className?: string;
  items: T[];
  onChangeItem?: (value: T) => void;
  typeTabs?: TypeTabs;
  selectedTab?: T;
  fullWidth?: boolean;
  fakeTab?: string[] | null;
}

export const Tabs = <T extends ValidRowModel>(props: ITabsProps<T>) => {
  const {
    className,
    items,
    onChangeItem,
    selectedTab,
    fakeTab,
    fullWidth,
    typeTabs = 'primary',
  } = props;

  const [selectItemLocal, setSelectItemLocal] = useState<T>();

  const onChangeItemLocal = (item: T) => {
    setSelectItemLocal(item);
    onChangeItem?.(item);
  };

  useEffect(() => {
    setSelectItemLocal(selectedTab || items[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const gap = useMemo(
    () => (typeTabs === 'screener' ? 'x-x-s' : '0'),
    [typeTabs],
  );
  const align = useMemo(
    () => (typeTabs === 'screener' ? 'end' : 'center'),
    [typeTabs],
  );

  return (
    <HStack
      max={fullWidth}
      className={classNames(cls[typeTabs], {}, [className])}
      gap={gap}
      align={align}
    >
      {!!items &&
        items.length > 0 &&
        items.map((item: T) => (
          <ItemTab
            key={item?.id}
            withIcon
            typeTabs={typeTabs}
            data={item}
            isActive={item?.id === selectItemLocal?.id}
            onClick={() => onChangeItemLocal(item)}
          />
        ))}
      {!!fakeTab &&
        fakeTab.length > 0 &&
        fakeTab.map(item => <FakeItemTab key={item} fake={item} />)}
    </HStack>
  );
};
