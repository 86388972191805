import { useTranslations } from 'next-intl';
import { FC, memo, useMemo } from 'react';
import cls from './fakeItemTab.module.css';
import { Icon } from '../../../Icon';
import { Tooltip } from '../../../Popovers';
import { Text } from '../../../Text';
import { TooltipSVG } from '@/shared/assets/svg/Main/Screener';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';

interface IFakeItemTab {
  className?: string;
  fake: string;
}

export const FakeItemTab: FC<IFakeItemTab> = memo(props => {
  const { className, fake } = props;

  const t = useTranslations();
  const { isMobile } = useDetectDevice();

  const iconSize = useMemo(() => (isMobile ? '16' : '20'), [isMobile]);

  const tooltip = useMemo(
    () => (
      <Tooltip classNameTooltip={cls.tooltip} titleText={t('Coming soon')} />
    ),
    [t],
  );

  return (
    <button
      disabled
      type='button'
      className={classNames(cls.inner, {}, [className])}
    >
      <Text variant='body-l-uppercase'>{fake}</Text>
      <Icon Svg={TooltipSVG} width={iconSize} height={iconSize} />
      {tooltip}
    </button>
  );
});
