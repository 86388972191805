import { ButtonHTMLAttributes, useMemo } from 'react';
import cls from './itemTab.module.css';
import { Icon } from '../../../Icon';
import { Text, Variants } from '../../../Text';
import { classNames, Mods } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { TypeTabs } from '@/shared/types/tabs';
import { SizeElement } from '@/shared/types/ui';

interface IItemTabProps<T> extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  data: T;
  isActive?: boolean;
  withIcon?: boolean;
  size?: SizeElement;
  typeTabs?: TypeTabs;
}

export const ItemTab = <T extends ValidRowModel>(props: IItemTabProps<T>) => {
  const {
    className,
    data,
    isActive = false,
    withIcon = false,
    size = 'large',
    typeTabs = 'primary',
    ...otherProps
  } = props;

  const { isMobile } = useDetectDevice();

  const variantText: Variants = useMemo(() => {
    if (typeTabs === 'cards') return 'body-m-medium';
    if (typeTabs === 'secondary')
      return isMobile ? 'body-s-medium' : 'body-l-medium';
    if (typeTabs === 'screener') return 'body-l-uppercase';
    return 'body-l';
  }, [typeTabs, isMobile]);

  const mods: Mods = {
    [cls['is-active']]: isActive,
    [cls[size]]: typeTabs === 'primary',
  };

  return (
    <button
      type='button'
      className={classNames(cls['item-tab'], mods, [cls[typeTabs]])}
      {...otherProps}
    >
      {!!withIcon && !!data.icon && <Icon Svg={data.icon} />}
      <Text variant={variantText}>{data?.name}</Text>
    </button>
  );
};
